<template>
  <div>
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-row>
        <div
          style="
            float: right;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 40%;
          "
        >
          <div
            @click="changeIndex(1)"
            :class="{ tabs: true, active: index == 1 }"
          >
            新售
          </div>
          <div
            @click="changeIndex(2)"
            :class="{ tabs: true, active: index == 2 }"
          >
            激活
          </div>
          <div
            @click="changeIndex(3)"
            :class="{ tabs: true, active: index == 3 }"
          >
            续卡
          </div>
          <div
            @click="changeIndex(4)"
            :class="{ tabs: true, active: index == 4 }"
          >
            本月到期
          </div>
        </div>
      </el-row>
    </div>
    <div class="container">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="用户手机号">
          <el-input v-model="options.mobile"></el-input>
        </el-form-item>
        <el-form-item label="渠道来源">
          <el-select v-model="options.platform">
            <!-- <el-option label='合作商' ></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="所属销售">
          <el-select v-model="options.admin">
            <!-- <el-option label="王小明" ></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="search">查询</el-button>
        </el-form-item>
      </el-form>

      <el-row>
        <el-table :data="user_card_list" v-loading="is_loading">
          <el-table-column label="ID" prop="id" width="80"></el-table-column>
          <el-table-column
            label="卡号"
            prop="card_id"
            width="120"
          ></el-table-column>
          <el-table-column label="发卡时间" prop="start_time"></el-table-column>
          <el-table-column
            label="用户昵称"
            prop="map.userinfo.nick_name"
          ></el-table-column>
          <el-table-column
            label="用户手机号"
            prop="map.userinfo.mobile"
          ></el-table-column>
          <el-table-column label="权益点数" align="center">
            <template slot-scope="{ row }">
              <div class="flex">
                <span>{{ row.map.point }}</span>
                <el-button type="text" @click="exchange(row)">调整</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="渠道来源" prop=""></el-table-column>
          <el-table-column label="所属销售" prop=""></el-table-column>
          <el-table-column
            label="到期时间"
            prop="expired_time"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="card_total"
        ></paging-fy>
      </el-row>
    </div>

    <el-dialog title="调整权益点" :visible.sync="dialogVisible" width="40%">
      <div>
        <el-form :inline="true" :model="changePoint" class="demo-form-inline">
          <el-form-item label="增加+">
            <el-input
              v-model="changePoint.addPoint"
              placeholder="增加点数"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="changePoint.addDesc"
              placeholder="备注"
            ></el-input>
          </el-form-item>

          <el-form-item label="减少-">
            <el-input
              v-model="changePoint.reducePoint"
              placeholder="减少点数"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="changePoint.reduceDesc"
              placeholder="备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="pointChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "会员卡管理",
        secondNav: "尊享卡管理",
      },
      user_card_list: [],
      card_total: 0,
      is_loading: false,
      currentPage: 1,
      index: 1,
      options: {},
      dialogVisible: false,
      changePoint: {
        addPoint: "",
        addDesc: "",
        reducePoint: "",
        reduceDesc: "",
      },
      changeId: "",
      cur: {},
      // member_level:
    };
  },
  mounted() {
    // this.getZhuanxiangId().then(res=>{
    //   if(res.data.data.rows.length){
    //     this.member_level = res.data.data.rows[0].member_level
    this.getUserCardList();
    // }
    // })
  },
  methods: {
    getZhuanxiangId() {
      let url = "/user/userMemberLevel/queryManagerListPage";
      return this.$axios.get(url, {
        params: {
          pageSize: 10,
          status: 1,
          currentPage: 1,
          platform: "体育宝",
          member_name: "优学卡",
        },
      });
    },
    changeIndex(index) {
      this.currentPage = 1;
      this.options = {};
      this.index = index;
      switch (index) {
        case 1:
      }
    },
    search() {
      this.getUserCardList();
    },
    getUserCardList(num) {
      this.is_loading = true;
      let url = "/user/userMember/queryManagerListPage";

      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            type: "优学卡",
            mobile: this.options.mobile,
            // ...this.options
            // member_level: this.member_level,
          },
        })
        .then((res) => {
          this.card_total = res.data.data.total;
          this.user_card_list = res.data.data.rows;
          this.is_loading = false;
        });
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getUserCardList();
    },
    exchange(row) {
      this.cur = row;
      this.dialogVisible = true;
    },
    pointChange() {
      let url = "";
      let num = 0;
      let reason = "";
      if (
        this.changePoint.addPoint != "" &&
        this.changePoint.reducePoint != ""
      ) {
        this.$message({
          message: "只能增加或减少",
          type: "info",
        });
        return;
      }
      if (this.changePoint.addPoint != "" && this.changePoint.addPoint > 0) {
        url = "/user/userPoint/updateAddPoint";
        num = this.changePoint.addPoint;
        reason = this.changePoint.addDesc;
      } else if (
        this.changePoint.reducePoint != "" &&
        this.changePoint.reducePoint > 0
      ) {
        url = "/user/userPoint/updateSubPoint";
        num = this.changePoint.reducePoint;
        reason = this.changePoint.reduceDesc;
      } else {
        this.$message({
          message: "请输入正确的点数",
          type: "info",
        });
        return;
      }
      this.$axios({
        url,
        data: {
          id: this.cur.point_id,
          point: Number(num),
          reason,
        },
        method: "post",
      }).then((res) => {
        this.changePoint = {
          addPoint: "",
          addDesc: "",
          reducePoint: "",
          reduceDesc: "",
        };
        if (res.data.code == 0) {
          this.getUserCardList();
          this.dialogVisible = false;
        }
        this.$message({
          message: res.data.message,
          type: "info",
        });
      });
    },
  },
};
</script>

<style scoped>
.tabs {
  cursor: pointer;
  padding: 0.3rem;
  font-size: 1.5rem;
}

.tabs.active {
  color: #6d9fff;
  border-bottom: 1px solid #6d9fff;
}

.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
